

















































import { Vue, Component, PropSync, Ref } from 'vue-property-decorator'
import { is, validate } from '@/utils/helpers'
import _ from 'lodash'
import { ElForm } from 'element-ui/types/form'

@Component
export default class ReportDialog extends Vue {
  @PropSync('visible', { default: false }) syncedVisible: boolean

  @Ref() form: ElForm

  data = {
    name: '',
    tel: '',
    reportingTime: '',
    reporter: '',
    identityNumber: '',
    protertyConsultant: '',
    description: ''
  }
  rules = {
    name: [{ required: true, message: '请输入客户姓名', trigger: 'blur' }],
    tel: [
      { required: true, message: '请输入客户手机号', trigger: 'blur' },
      {
        trigger: 'blur',
        validator: (_: object, value: string, callback: Function) => {
          if (!validate.phoneNumber(value)) {
            callback(new Error('请输入正确的手机号'))
          } else {
            callback()
          }
        }
      }
    ],
    reportingTime: [{ required: true, message: '请选择报备日期', trigger: 'blur' }],
    reporter: [{ required: true, message: '请输入报备人', trigger: 'blur' }],
    identityNumber: [
      {
        trigger: 'blur',
        validator: (_: object, value: string, callback: Function) => {
          if (value && !validate.identityNumber(value)) {
            callback(new Error('请输入正确的身份证号'))
          } else {
            callback()
          }
        }
      }
    ]
  }

  async submit() {
    const valid = await this.form.validate()

    if (valid) {
      const { data } = await this.$api.base.createCustomerReport(_.pickBy(this.data, is.ava))

      if (data.code === 0) {
        this.$emit('created')
        this.close()
      }
    }
  }

  close() {
    this.form.clearValidate()
    this.form.resetFields()
    this.syncedVisible = false
  }
}
