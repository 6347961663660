










import { Vue, Component } from 'vue-property-decorator'
import PageTitle from '@/components/common/pageTitle.vue'
import ReportsTable from './ReportsTable.vue'

@Component({ components: { PageTitle, ReportsTable } })
export default class ClientAppointment extends Vue {}
