var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"reports-table"},[_c('header',[_c('filter-hub',{ref:"filterHub",attrs:{"name":_vm.name,"ctrl-visible":{ clear: true, lock: _vm.lockable }},on:{"filter":function($event){return _vm.filter()}},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var locked = ref.locked;
return [_c('filter-item',[_c('date-range-picker',{attrs:{"disabled":locked},model:{value:(_vm.filterData.dateRange),callback:function ($$v) {_vm.$set(_vm.filterData, "dateRange", $$v)},expression:"filterData.dateRange"}})],1),_c('filter-item',{attrs:{"trigger":[]}},[_c('el-input',{staticClass:"search-input",attrs:{"placeholder":_vm.placeholder,"disabled":locked,"clearable":""},on:{"clear":_vm.filter},nativeOn:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.filter.apply(null, arguments)}},model:{value:(_vm.filterData.searchValue),callback:function ($$v) {_vm.$set(_vm.filterData, "searchValue", $$v)},expression:"filterData.searchValue"}},[_c('el-select',{attrs:{"slot":"prepend","disabled":locked},slot:"prepend",model:{value:(_vm.filterData.searchType),callback:function ($$v) {_vm.$set(_vm.filterData, "searchType", $$v)},expression:"filterData.searchType"}},_vm._l((_vm.searchTypes),function(item){return _c('el-option',{key:item.value,attrs:{"label":item.label,"value":item.value}})}),1),_c('el-button',{attrs:{"slot":"append","icon":"el-icon-search","disabled":locked},on:{"click":_vm.filter},slot:"append"})],1)],1)]}}]),model:{value:(_vm.filterData),callback:function ($$v) {_vm.filterData=$$v},expression:"filterData"}})],1),_c('main',[_c('div',{staticClass:"table-header"},[_c('span',{staticClass:"title"},[_vm._v("报备列表")]),(!_vm.readonly)?_c('el-button',{staticClass:"primary-btn",on:{"click":function($event){return _vm.createReport()}}},[_vm._v("新增报备")]):_vm._e()],1),_c('el-table',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],attrs:{"data":_vm.data,"height":"auto"}},[_c('el-table-column',{attrs:{"width":"55"}}),_c('el-table-column',{attrs:{"label":"客户姓名","prop":"name"}}),_c('el-table-column',{attrs:{"label":"手机号","prop":"tel"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('SecurityText',{attrs:{"visible":row.telVisible,"text":row.tel ? row.tel.replace('+86', '') : null,"type":"mobilePhone"},on:{"update:visible":function($event){return _vm.$set(row, "telVisible", $event)}}})]}}])}),_c('el-table-column',{attrs:{"label":"报备人","prop":"reporter"}}),_c('el-table-column',{attrs:{"label":"置业顾问","prop":"protertyConsultant"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(_vm._s(row.protertyConsultant || '-'))]}}])}),_c('el-table-column',{attrs:{"label":"报备日期"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_vm._v(" "+_vm._s(_vm.$moment(scope.row.reportingTime).format('YYYY-MM-DD'))+" ")]}}])}),_c('el-table-column',{attrs:{"label":"客户身份证号","prop":"identityNumber"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('SecurityText',{attrs:{"visible":row.identityNumberVisible,"text":row.identityNumber,"type":"identityNumber"},on:{"update:visible":function($event){return _vm.$set(row, "identityNumberVisible", $event)}}})]}}])}),_c('el-table-column',{attrs:{"label":"报备添加时间"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_vm._v(" "+_vm._s(_vm.$moment(scope.row.createdAt).format('YYYY-MM-DD HH:mm:ss'))+" ")]}}])}),(!_vm.readonly)?_c('el-table-column',{attrs:{"label":"操作"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-button',{attrs:{"type":"text"},on:{"click":function($event){return _vm.deleteReport(row)}}},[_vm._v("删除")])]}}],null,false,3764813440)}):_vm._e(),_vm._t("default")],2),_c('el-pagination',{attrs:{"page-sizes":[20, 50, 100],"current-page":_vm.pagination.current,"page-size":_vm.pagination.limit,"total":_vm.total,"layout":"total, sizes, prev, pager, next, jumper"},on:{"size-change":_vm.fetchData,"current-change":_vm.fetchData,"update:currentPage":function($event){return _vm.$set(_vm.pagination, "current", $event)},"update:current-page":function($event){return _vm.$set(_vm.pagination, "current", $event)}}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }