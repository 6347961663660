
























































































































import { Vue, Component, Prop } from 'vue-property-decorator'
import SecurityText from '@/components/common/SecurityText/index.vue'
import Modal from '@/components/common/Modal'
import ReportDialog from './ReportDialog.vue'
import { validate } from '@/utils/helpers'

type Report = AnyObj

@Component({ components: { SecurityText } })
export default class ReportsTable extends Vue {
  @Prop({ type: String, default: 'client-appointment' }) name: string
  @Prop({ type: Boolean, default: false }) readonly: boolean
  @Prop({ type: Boolean, default: true }) lockable: boolean

  filterData = {
    dateRange: [this.$moment().startOf('day'), this.$moment().endOf('day')],
    searchType: 'name',
    searchValue: ''
  }
  searchTypes = [
    { label: '客户姓名', value: 'name' },
    { label: '客户手机号', value: 'tel' },
    { label: '客户身份证号', value: 'identityNumber' }
  ]
  searchRules: { [K in string]?: (v: string) => string | void } = {
    tel: v => {
      if (!validate.phoneNumber(v) && !validate.landlineNumber(v)) return '请输入正确的电话号码'
    },
    identityNumber: v => {
      if (!validate.identityNumber(v)) return '请输入正确的身份证号'
    }
  }
  loading = false
  data: Report[] = []
  total = 0
  pagination = { current: 1, limit: 20 }
  dialog = Modal(ReportDialog)

  get placeholder() {
    const type = this.searchTypes.find(({ value }) => value === this.filterData.searchType)

    return '请输入' + (type?.label ?? '')
  }

  get params() {
    const { limit, current } = this.pagination
    const offset = (current - 1) * limit
    const { dateRange, searchType, searchValue } = this.filterData
    const [startTime, endTime] = dateRange

    const params: Record<string, any> = { limit, offset, startTime, endTime }

    if (searchType && searchValue) {
      params[searchType] = searchValue
    }

    return params
  }

  deactivated() {
    this.dialog.close()
  }

  async mounted() {
    this.fetchData()

    this.dialog.on('created', this.fetchData)
  }

  async fetchData() {
    const { searchType, searchValue } = this.filterData

    if (searchValue) {
      const validate = this.searchRules[searchType]
      const message = validate && validate(searchValue)

      if (message) {
        return this.$message({ message, type: 'error' })
      }
    }

    this.loading = true
    const { data } = await this.$api.base.searchCustomerReports(this.params)

    this.loading = false

    if (data.code === 0) {
      this.data = data.data
      this.total = data.count
    }
  }

  filter() {
    this.pagination.current = 1
    this.fetchData()
  }

  createReport() {
    this.dialog.open()
  }

  deleteReport(row: AnyObj) {
    this.$confirm('报备信息一经删除无法恢复，是否确定删除？', '提示')
      .then(async () => {
        await this.$api.base.deleteCustomerReport(row.id)
        this.fetchData()
      })
      .catch(() => {})
  }
}
